/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import PageContentAreaModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageContentAreaModules.jsx";
import usePageContent from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/usePageContent.js";
import {
  PageBreadcrumbs,
  Seo,
  TextContent,
  Html,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  Section,
  PageGrid,
  PageGridItem,
  PageSection,
  Typography,
  Button,
} from "@wsui/base";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";

// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
import Brochure from "../../../../Brochure.jsx";
import Image from "../components/Image.jsx";

export default function DefaultTemplate({ pageContext }) {
  const theme = useTheme();
  const {
    title,
    isFrontPage,
    featuredImage,
    brochure,
    productData: { usps, gallery, shortDescription },
  } = pageContext;

  let strippedCaption = featuredImage.node.caption?.replace(
    /(<([^>]+)>)/gi,
    "",
  );

  const { content, preamble } = usePageContent();

  const [showContent, setShowContent] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [brochureModalIsOpen, setBrochureModalIsOpen] = useState(false);
  return (
    <article>
      <Seo title={title} isFrontPage={isFrontPage} />
      <PageBreadcrumbs
        background="paxxo"
        css={css`
          padding-bottom: 8rem;
          --icon-color: ${theme.getColor("white")};
        `}
      />

      <PageSection
        background="transparent"
        css={css`
          margin-top: -8rem;
        `}
      >
        <PageGrid>
          <PageGridItem colspan={14}>
            <div
              css={css`
                display: grid;
                column-gap: 40px;
                align-content: start;
                @media (max-width: 640px) {
                  display: flex;
                  flex-direction: column;
                }
                @media (min-width: 641px) {
                  grid-template-columns: 60% auto; /* Two columns */
                  grid-template-areas:
                    "image details"
                    "body details";
                }
              `}
            >
              <div
                css={css`
                  grid-area: image;
                `}
              >
                <Image
                  {...featuredImage.node}
                  caption={strippedCaption}
                  borderRadius={3}
                />
              </div>

              <div
                css={css`
                  grid-area: details;
                `}
              >
                <div
                  css={css`
                    height: 140px;
                    display: flex;
                  `}
                >
                  <Typography
                    as="h1"
                    variant="h2"
                    marginAfter
                    marginBefore
                    css={css`
                      display: flex;
                      align-self: flex-end;

                      color: ${theme.getColor("white")};
                      @media (max-width: 640px) {
                        color: ${theme.getColor("black")};
                      }
                    `}
                  >
                    {title}
                  </Typography>
                </div>

                <div
                  css={css`
                    display: flex;
                    margin-top: 12px;

                    ::before {
                      content: "\\00a0";
                      width: 19px;
                      background-color: ${theme.getColor("yellow")};
                      border-radius: 9px;
                      margin-right: 16px;
                    }

                    > p > div > p:first-child {
                      margin-top: 0;
                    }

                    > p > div > p:last-child {
                      margin-bottom: 0;
                    }

                    @media (max-width: 640px) {
                      margin-bottom: 2rem;
                    }
                  `}
                >
                  <Typography variant="body">
                    <div>
                      <Html>{shortDescription}</Html>
                    </div>
                  </Typography>
                </div>
                <ul
                  css={css`
                    background-color: ${theme.getColor("yellow.light")};
                    padding: 35px 35px 25px 25px;
                    list-style-type: none;
                  `}
                >
                  {usps?.map((usp) => (
                    <li
                      key={usp.title}
                      css={css`
                        background-image: url("/icons/check.svg");
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        padding-left: 45px;
                        margin-bottom: 20px;
                      `}
                    >
                      <div css={css``}>
                        <Typography as="h3" variant="bodyBold">
                          {usp.title}
                        </Typography>
                        <Typography variant="body">{usp.text}</Typography>
                      </div>
                    </li>
                  ))}
                </ul>
                {brochure?.brochureGroup?.brochures?.length && (
                  <Button
                    onClick={() => {
                      setBrochureModalIsOpen(true);
                    }}
                    color="primary"
                    style={{
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {brochure?.brochureGroup?.buttonText ?? "Hämta broschyr"}
                  </Button>
                )}
              </div>

              <div
                css={css`
                  grid-area: body;
                `}
              >
                <TextContent variant="description" marginBefore>
                  {preamble ?? content}
                </TextContent>

                <TextContent
                  variant="description"
                  style={{ display: showContent ? "block" : "none" }}
                >
                  {content}
                </TextContent>
                {preamble && content && (
                  <Typography
                    variant="description"
                    css={css`
                      text-align: right;
                      margin: 0;
                    `}
                  >
                    <Button onClick={() => setShowContent(!showContent)}>
                      {showContent ? "Dölj" : "Läs mer"}
                    </Button>
                  </Typography>
                )}
                <div
                  css={css`
                    margin-top: 1rem;
                    display: grid;
                    grid-template-columns: repeat(
                      4,
                      minmax(min(100%, 40px), 1fr)
                    );
                    row-gap: ${theme.getLength(6)};
                    column-gap: ${theme.getLength(6)};

                    @media (max-width: 640px) {
                      grid-template-columns: repeat(
                        2,
                        minmax(min(100%, 40px), 1fr)
                      );
                    }
                  `}
                >
                  {gallery?.map((item) => (
                    <Image
                      key={item.title}
                      {...item}
                      borderRadius={1}
                      hideCaption={true}
                      height={320}
                      width={320}
                      css={css`
                        cursor: pointer;
                      `}
                      onClick={() => {
                        setSelectedImage(item);
                        setLightboxIsOpen(true);
                      }}
                    />
                  ))}
                </div>
                {lightboxIsOpen && (
                  <Lightbox
                    open={lightboxIsOpen}
                    close={() => setLightboxIsOpen(false)}
                    slides={[
                      {
                        src: selectedImage?.src,
                        alt: selectedImage?.alt,
                      },
                      ...gallery
                        .filter((item) => item.src !== selectedImage?.src)
                        .map((item) => ({
                          src: item.src,
                          alt: item.alt,
                        })),
                    ]}
                  />
                )}
                {brochureModalIsOpen && (
                  <Brochure
                    brochures={brochure?.brochureGroup?.brochures}
                    title={brochure?.brochureGroup?.title}
                    description={brochure?.brochureGroup?.description}
                    setBrochureModalIsOpen={setBrochureModalIsOpen}
                  />
                )}
              </div>
            </div>
          </PageGridItem>
        </PageGrid>
        <PageGrid>
          <PageGridItem colspan={7}>
            <Section />
          </PageGridItem>
        </PageGrid>
      </PageSection>
      <Section>
        <PageContentAreaModules maxColspan={7} />
      </Section>
    </article>
  );
}
